// import { TweenMax, Power3, Power4 } from "gsap";
import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import rxReminder from '../images/website-graphics/rx-reminder.png';
import AnimalForSale from '../images/website-graphics/animal-for-sale.png';
import NearestVet from '../images/website-graphics/nearest-vet.png';

gsap.registerPlugin(ScrollTrigger);

function AppFeatures() {
	useEffect(() => {
		let ctx = gsap.context(() => {
			gsap.set(".photo:not(:first-child)", { opacity: 0, scale: 0.5 })
			const animation = gsap.to(".photo:not(:first-child)", {
				opacity: 1, scale: 1, duration: 1, stagger: 1
			})

			ScrollTrigger.create({
				trigger: ".gallery",
				start: "top top",
				end: "bottom bottom",
				pin: ".rightblock",
				animation: animation,
				scrub: true,
				// markers: true,
			})
		})
		return () => ctx.revert();
	}, [])
	return (
		<section className="appdetails bg-logo-50">
			<div className="pt-5 mt-3 text-left container">
				<Box className="gallery" sx={{ display: "flex" }}>
					<Box className="left" sx={{
						width: "50%",
						marginLeft: "auto",
						"& .details": {
							height: "100vh",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							width: "100%",
							marginLeft: "auto",
							color: "#000",
							fontSize: "3rem",
							fontWeight: 900,
						}
					}}>
						<Box className="details">
							<div className='vh-100'>
								<div className='d-flex flex-column align-items-start justify-content-center h-100'>
									<h2 className="display-2 fw-semibold text-slate-800">Nearest Vet</h2>
									<p className="mt-3 pb-4 fs-5 text-slate-600">Aut qui hic atque tenetur quis eius quos ea neque sunt, accusantium soluta minus veniam tempora deserunt? Molestiae eius quidem quam repellat.</p>
									<Link to="#" className="btn btn-primary btn-logo inline-block rounded-pill px-5 py-3 fw-medium text-white">Know More</Link>
								</div>
							</div>
						</Box>
						<Box className="details">
							<div className='vh-100'>
								<div className='d-flex flex-column align-items-start justify-content-center h-100'>
									<h2 className="display-3 fw-semibold text-slate-800">Animal Buy/Sell</h2>
									<p className="mt-3 pb-4 fs-5 text-slate-600">Aut qui hic atque tenetur quis eius quos ea neque sunt, accusantium soluta minus veniam tempora deserunt? Molestiae eius quidem quam repellat.</p>
									<Link to="#" className="btn btn-primary btn-logo inline-block rounded-pill px-5 py-3 fw-medium text-white">Know More</Link>
								</div>
							</div>
						</Box>
						<Box className="details">
							<div className='vh-100'>
								<div className='d-flex flex-column align-items-start justify-content-center h-100'>
									<h2 className="display-3 fw-semibold text-slate-800">RxReminder</h2>
									<p className="mt-3 pb-4 fs-5 text-slate-600">Aut qui hic atque tenetur quis eius quos ea neque sunt, accusantium soluta minus veniam tempora deserunt? Molestiae eius quidem quam repellat.</p>
									<Link to="#" className="btn btn-primary btn-logo inline-block rounded-pill px-5 py-3 fw-medium text-white">Know More</Link>
								</div>
							</div>
						</Box>
					</Box>
					<Box className="rightblock" sx={{
							width: "50%",
							height: "100vh",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
					}}>
						<Box sx={{
							width: "40vw",
							height: "100vh",
							position: "relative",
							"& .photo": {
								position: "absolute",
								width: "100%",
								height: "100%",
								// "& img": { height: "100%", width: "100%", }
							}
						}}>
							<Box className="photo">
								<div className='app-scroll'>
									<div className='mobile-screens'>
										<img src={NearestVet} alt="rx Reminder"/>
									</div>
								</div>
							</Box>
							<Box className="photo">
								<div className='app-scroll'>
									<div className='mobile-screens'>
										<img src={AnimalForSale} alt="Animal For Sale"/>
									</div>
								</div>
							</Box>
							<Box className="photo">
								<div className='app-scroll'>
									<div className='mobile-screens'>
										<img src={rxReminder} alt="rx Reminder"/>
									</div>
								</div>
							</Box>
						</Box>
					</Box>
				</Box>
			</div>
		</section>
	);
}

export default AppFeatures;