import { Link } from 'react-router-dom';
import pashumitraApp from '../images/website-graphics/index-page-app-fetures.png';
import rxReminder from '../images/website-graphics/rx-reminder.png';
import AnimalForSale from '../images/website-graphics/animal-for-sale.png';
import NearestVet from '../images/website-graphics/nearest-vet.png';

import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const StickySection = () => {

  useEffect(() => {
		let ctx = gsap.context(() => {
			gsap.set(".photo:not(:first-child)", { opacity: 0 , scale: 0 })
			const animation = gsap.to(".photo:not(:first-child)", {
				opacity: 1, scale: 1, duration: 1, stagger: 1, ease: "power2.out",
			})

			ScrollTrigger.create({
				trigger: ".appdetails",
				start: "top top",
				end: "bottom bottom",
				pin: ".left-column-inner",
				animation: animation,
				scrub: true,
				// markers: true,
			})
		})
		return () => ctx.revert();
	}, [])

  return (
    <div className='appdetails bg-logo-50'>
      <div className="container">
      <div className="row">
        <div className="left-column">
          <div className="left-column-inner">
            <div className='app-scroll'>
              {/* <img src={pashumitraApp} alt="Pashumitra App" className='mobile-app'/> */}
              <div className='mobile-screens photo'>
                <img src={AnimalForSale} alt="Animal For Sale"/>
              </div>
              <div className='mobile-screens photo'>
                <img src={NearestVet} alt="rx Reminder"/>
              </div>
              <div className='mobile-screens photo'>
                <img src={rxReminder} alt="rx Reminder"/>
              </div>
            </div>
          </div>
        </div>
        <div className="right-column">
          {/* <div className='h-25'></div> */}
          <div className='vh-100'>
            <div className='d-flex flex-column align-items-start justify-content-center h-100'>
              <h2 className="display-3 fw-semibold text-slate-800">Buy/Sell Animals</h2>
              <div className='mobile-view-stick'>
                <img src={AnimalForSale} alt="Animal For Sale" className='img-fluid'/>
              </div>
              {/* <p className="mt-1 mb-0 fs-5 text-slate-600">The livestock farmer advertises to sell various domestic animals.</p> */}
              <ul className='appdetails-list text-slate-600'>
                <li className='fs-5 lh-2'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi text-logo" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/></svg> The livestock farmer advertises to sell various domestic animals.</li>
                <li className='fs-5 lh-2'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi text-logo" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/></svg> Suitable local prices are available without the need to visit the market.</li>
                <li className='fs-5 lh-2'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi text-logo" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/></svg> Direct farmer-to-farmer transactions prevent market manipulation.</li>
              </ul>
              {/* <Link to="#" className="btn btn-primary btn-logo inline-block rounded-pill px-5 py-3 fw-medium text-white">Know More</Link> */}
            </div>
          </div>
          <div className='vh-100'>
            <div className='d-flex flex-column align-items-start justify-content-center h-100'>
              <h2 className="display-2 fw-semibold text-slate-800">Nearest Vet</h2>
              <div className='mobile-view-stick'>
                <img src={AnimalForSale} alt="Animal For Sale" className='img-fluid'/>
              </div>
              <ul className='appdetails-list text-slate-600'>
                <li className='fs-5 lh-2'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi text-logo" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/></svg> Receive comprehensive details about the nearest veterinarian,  experience, location and contact information.</li>
                <li className='fs-5 lh-2'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi text-logo" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/></svg> Know whether they accept walk-ins or require appointments for convenience.</li>
                <li className='fs-5 lh-2'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi text-logo" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/></svg> Access phone consultations for immediate advice and assistance.</li>
              </ul>
              {/* <Link to="#" className="btn btn-primary btn-logo inline-block rounded-pill px-5 py-3 fw-medium text-white">Know More</Link> */}
            </div>
          </div>
          <div className='vh-100'>
            <div className='d-flex flex-column align-items-start justify-content-center h-100'>
              <h2 className="display-3 fw-semibold text-slate-800">Rx/Reminder</h2>
              <div className='mobile-view-stick'>
                <img src={AnimalForSale} alt="Animal For Sale" className='img-fluid'/>
              </div>
              <ul className='appdetails-list text-slate-600'>
                <li className='fs-5 lh-2'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi text-logo" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/></svg> Set reminders for your animal's vaccinations or regular doctor visits.</li>
                <li className='fs-5 lh-2'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi text-logo" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/></svg> Both the veterinarian and the animal owner can schedule RX reminders for timely healthcare.</li>
              </ul>
              {/* <Link to="#" className="btn btn-primary btn-logo inline-block rounded-pill px-5 py-3 fw-medium text-white">Know More</Link> */}
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default StickySection;
