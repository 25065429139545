import { Route, Routes } from 'react-router-dom'
import App from './App';
import About from './pages/About';
import Contact from './Contact';
import CsrActivities from './pages/CsrActivities';
import CsrActivityDetail from './pages/CsrActivityDetail';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Download from './pages/Download';

function PagesRoutes() {
  return (
    <>
      <Routes>
          <Route exact path='/' element={<App/>}/>
          <Route exact path='/about' element={<About/>}/>
          <Route exact path='/contact-us' element={<Contact/>}/>
          <Route exact path='/csr-activities' element={<CsrActivities/>}/>
          <Route exact path="/csr-activity/:id" element={<CsrActivityDetail/>} />
          <Route exact path='/terms-conditions' element={<TermsConditions/>}/>
          <Route exact path='/privacy-policy' element={<PrivacyPolicy/>}/>
          <Route exact path='/download' element={<Download/>}/>
      </Routes>
    </>
  )
}

export default PagesRoutes;
