import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import axios from 'axios';

// const baseUrl = "http://localhost:8000";
const baseUrl = "https://server.pashumitra.com";

function Contact() {
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    category: '',
    message: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseUrl}/email/sendEmail`, formData);
      // alert('Form submitted successfully!');
      setSubmitted(true); // Set submitted to true to show the success message
      setSuccessMessage('Form submitted successfully!');
      setFormData(initialFormData); // Reset form fields to initial empty values
    } catch (error) {
      // console.error('Error submitting form:', error);
      setSubmitted(false);
      setErrorMessage('Error submitting form: ' + error.message);
    }
  };

  return (
    <>
      <Header />
      <section className="contactPage">
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-6">
              <h1 className="display-2 fw-semibold text-slate-800 mb-3 lh-2">
                <span className="d-block fs-5 text-uppercase text-logo">Contact Us</span>
                How May We Help You!
              </h1>
              <div className="row">
                <div className="col-lg-6">
                  <div className="bg-white rounded-3 border contact-info1">
                    <span className="d-block fs-5 text-slate-800 fw-semibold">Call Now</span>
                    <Link to="tel:8805700570" className="fs-6 text-slate-700"> +91 8805700570 </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="bg-white rounded-3 border contact-info1">
                    <span className="d-block fs-5 text-slate-800 fw-semibold">Email</span>
                    <a href="mailto:info@pashumitra.com" className="fs-6 text-slate-700"> info@pashumitra.com</a>
                  </div>
                </div>
                <div className="lg:col-span-12">
                  <div className="bg-white rounded-3 border contact-info2">
                    <span className="d-block fs-5 text-slate-800 fw-semibold">Address</span>
                    <address className="fs-6 text-slate-700 mb-0">
                      S-12, Regimental Plaza, Gaikwad Mala, Bitco Point, Nashik Road, Maharashtra, India 422101
                    </address>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="bg-logo-50 rounded-3 contact-frm-wrap">
                <h2 className="fs-3 fw-semibold text-slate-800 mb-3">Fill the form</h2>
                {/* {submitted && <div className="alert alert-success"><strong>Success!</strong> Form submitted successfully!</div>}  */}
                {/* {submitted && !errorMessage && <div className="alert alert-success"><strong>Success!</strong> Form submitted successfully!</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} */}
                {submitted && !errorMessage && <div className="alert alert-success"><strong>Success!</strong>{successMessage}</div>}
                {errorMessage && <p className="text-danger">{errorMessage}</p>}

                <form onSubmit={handleSubmit} className="gap-3">
                  <div className="col-lg-12">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                      required
                      className="block w-100 cursor-pointer border border-gray-200 p-4 hover:border-black rounded"
                    />
                  </div>
                  <div className="row my-4">
                    <div className="col-lg-6">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Your Email"
                        required
                        className="block w-100 cursor-pointer border border-gray-200 p-4 hover:border-black rounded mobile-mb-10"
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Your Phone"
                        required
                        className="block w-100 cursor-pointer rounded border border-gray-200 p-4 hover:border-black col"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <select
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      required
                      className="block w-100 cursor-pointer border border-gray-200 p-4 hover:border-black rounded"
                    >
                      <option value="" className='text-slate-500'>Choose a category</option>
                      <option value="Formal Advice">Formal Advice</option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Add Personal Data">Add Personal Data</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-lg-12">
                    <textarea name="message" value={formData.message}
                      onChange={handleChange}
                      placeholder="Your Message"
                      required
                      className="block w-100 cursor-pointer  border border-gray-200 p-4 hover:border-black rounded"
                    />
                  </div>
                  <button type="submit" className="btn rounded-pill btn-primary btn-logo px-5 py-3 fw-medium text-white mt-3">Send Enquiry</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
