import Header from '../components/Header'
import Footer from '../components/Footer'
import founderceo from '../images/pashumitra-founder-and-ceo.jpeg'

function About() {
  return (
    <div className='aboutpage'>
      <Header/>
        <section className="container pt-5">
          <div className="row">
              <div className="col-lg-10">
                  <h1 className='display-2 fw-semibold text-slate-950 mb-3'><span className='d-block fs-5 text-uppercase text-logo'>About us</span>Who are Pashumitra!</h1>
              </div>
              <div className="col-lg-12">
                  <p className='fs-5'><span className='text-logo'>Pashumitra Enterprises</span> is bringing the approach of considering the interest of the Livestock Owner and implementing various activities for it so that the Livestock Owner does not suffer and they get maximum benefit by providing support and guidance in the animal husbandry business. At present, it is carrying out an ambitious plan to implement a pilot project in Maharashtra and to implement it all over India and later globally.</p>
              </div>
          </div>
        </section>

        {/* Chairman talks */}
        <section className='chairman-talks'>
          <div className="container bg-slate-100 rounded-5">
              <div className="row">
                  <div className="col-lg-3">
                    <div className='founderimg'>
                      <img src={founderceo} alt=' Pashumitra founder and CEO' className='img-fluid'/>
                    </div>
                  </div>
                  <div className="h-full flex flex-col justify-center items-start col-lg-9 py-3 mt-3 mb-3">
                      <p className="fs-5">Unbelievable, One mobile application for Veterinary Graduates, Veterinary Diploma holders, Livestock Owners, Veterinary Students, Dairy Farm related products Manufacturers, Suppliers, Producers, Sellers, Veterinary Medicine Stores , Animal Breeder, Animal Transporters, Pet Clinician, Specialists, Veterinary Pathologist, Artificial Inseminators, Fodder & Seed suppliers and many more. Everything for livestock owners in one place. Thank You Team PashuMitra. There will be no cheating between producers and consumers. Cheating of cattle traders in buying and selling animals will be avoided. Also, since the producer will give the product to the actual consumer, it will not be adulterated and will be available at a reasonable price. This mobile application will revolutionize animal husbandry. Best wishes from me for this.</p>
                      <blockquote className="blockquote">
                        <p className="fw-semibold fs-4">Dr. Pramod S. Mahajan,</p>
                        <footer className="blockquote-footer fs-6 text-logo fw-semibold">Retd. Assistant Commissioner (AH), <cite title="Source Title" className='text-dark fw-medium'>Maharashtra State</cite></footer>
                      </blockquote>
                  </div>
              </div>
          </div>
        </section>

        <section className="py-5 mb-3">
          <div className="container">
            <div className='row'>
              <h2 className="display-3 fw-semibold text-slate-800 mb-4 text-center">Countless Success Stories</h2>
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <div className="row justify-content-center">
                  <div className="col">
                    <div className="text-center">
                      <h3 className="fs-5 fw-semibold mb-0 text-slate-800">Total Farmers</h3>
                      <p className="display-5 fw-semibold  text-logo">2k+</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-center">
                      <h3 className="fs-5 fw-semibold mb-0 text-slate-800">Registered Vet</h3>
                      <p className="display-5 fw-semibold  text-logo">3k+</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-center">
                      <h3 className="fs-5 fw-semibold mb-0 text-slate-800">Pashumitra</h3>
                      <p className="display-5 fw-semibold  text-logo">1k+</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer/>
    </div>
  )
}

export default About