import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PashumitraLogo from '../images/pashumitra-logo.svg'

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const toggleMobileMenu = () => { setMenuOpen(!menuOpen) };
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolling) {
        setScrolling(isScrolled);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolling]);
  const headerClass = scrolling ? 'scrolled' : '';

  return (
    <header id="mastheadHome" className={headerClass}>
      <div className="container">
        <div className="header-row h-16">
          <div className="logo-nav">
            <Link to="/" className="text-black fw-bold logo">
              <img src={PashumitraLogo} alt='Pashumitra Logo'/>
            </Link>
            <ul className={`topnav ${menuOpen ? 'mobile-hidden' : ''}`}>
                <li><Link to="/" className="text-slate-950">Home</Link></li>
                <li><Link to="/about" className="text-slate-950">About</Link></li>
                <li><Link to="/csr-activities" className="text-slate-950">CSR Activities</Link></li>
                <li><Link to="/contact-us" className="text-slate-950">Contact</Link></li>
                <li><Link to="/download" className="consulting-lia desk-none fw-semibold text-logo">Download App</Link></li>
            </ul>
            <div className={`burger-menu ${menuOpen ? 'active' : ''}`} onClick={toggleMobileMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
          </div>
          {/* <ul className="useoptions"> */}
          <ul className="useoptions mobile-none">
            <li className="login"><Link to="/download" className="text-uppercase text-logo fw-semibold border-bottom">Download App</Link></li>
          </ul>
        </div>
      </div>
    </header>
  );
}